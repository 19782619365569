import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { renderClasses } from "utils/renderClasses"
import Icon from "components/elements/Icon"
import "./logo.scss"
import WholeChildLogo from "../../../../../../static/images/WCCC-Logo.svg"
const Logo = ({ isWholeChild, menuOpen }) => {
  const fillColor = menuOpen || isWholeChild ? "#ffffff" : "#10389A"
  return (
    <div
      className={renderClasses("g-header-top-nav-logo", [[menuOpen, "--open"]])}
    >
      {isWholeChild ? (
        <Link aria-label="Navigate to Whole Child Home page" to="/whole-child">
          <img
            alt="Whole Child Logo"
            className="whole-child-logo"
            src={WholeChildLogo}
          />
        </Link>
      ) : (
        <Link aria-label="Navigate to Home page" to="/">
          <Icon
            aria-label="Site Logo"
            fill={fillColor}
            icon="Logo"
            inverted={menuOpen}
          />
        </Link>
      )}
    </div>
  )
}

Logo.defaultProps = {
  isWholeChild: false,
}

Logo.props = {
  menuOpen: PropTypes.bool.isRequired,
  isWholeChild: PropTypes.bool,
}

Logo.propTypes = Logo.props

export default Logo
