import React from "react"
import PropTypes from "prop-types"
import { renderClasses } from "utils/renderClasses"
import Logo from "./Logo"
import Menu from "./Menu"
import Action from "./Action"
import "./topnav.scss"

const TopNav = ({
  backgroundColor,
  cta,
  isWholeChild,
  menu,
  menuOpen,
  menuToggleHandler,
  utilityLinks,
}) => {
  return (
    <div
      className={renderClasses(
        "g-header-top-nav",
        [[backgroundColor, `--${backgroundColor}`]],
        [[menuOpen, "--open"]]
      )}
    >
      <div className="max-container">
        <Logo isWholeChild={isWholeChild} menuOpen={menuOpen} />
        <Menu
          {...menu}
          isWholeChild={isWholeChild}
          menuOpen={menuOpen}
          utilityLinks={utilityLinks}
        />
        {cta && (
          <Action
            {...cta}
            isWholeChild={isWholeChild}
            menuOpen={menuOpen}
            menuToggleHandler={menuToggleHandler}
          />
        )}
      </div>
    </div>
  )
}

TopNav.props = {
  ...Menu.props,
  cta: PropTypes.shape(Action.props),
}

TopNav.propTypes = {
  ...TopNav.props,
  backgroundColor: PropTypes.string,
  menuOpen: PropTypes.bool.isRequired,
  menuToggleHandler: PropTypes.func.isRequired,
}

export default TopNav
