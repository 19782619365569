import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import FlipText from "components/elements/flipText"
import ToolTip from "components/elements/toolTip"
import Heading1 from "components/type/Heading1"
import Heading2 from "components/type/Heading2"
import Heading3 from "components/type/Heading3"
import Heading4 from "components/type/Heading4"
import Heading5 from "components/type/Heading5"
import Heading6 from "components/type/Heading6"
import Paragraph from "components/type/Paragraph"

export const renderRichText = ({ isAccordion = false, json, type = "p2" }) => {
  let paragraphIndex = 0
  const defaultNodeRenderers = {
    [BLOCKS.DOCUMENT]: (_, children) => children,
    [BLOCKS.PARAGRAPH]: (_, children) => {
      const content = children.filter(c => (Array.isArray(c) ? c[1] !== "" : c))
      if (content.length === 0) return null
      const colorClass = isAccordion
        ? `g-paragraph--color-${(paragraphIndex % 6) + 1}`
        : ""
      paragraphIndex++
      return (
        <Paragraph className={colorClass} type={type}>
          {content}
        </Paragraph>
      )
    },
    [BLOCKS.HEADING_1]: (_, children) => <Heading1>{children}</Heading1>,
    [BLOCKS.HEADING_2]: (_, children) => <Heading2>{children}</Heading2>,
    [BLOCKS.HEADING_3]: (_, children) => <Heading3>{children}</Heading3>,
    [BLOCKS.HEADING_4]: (_, children) => <Heading4>{children}</Heading4>,
    [BLOCKS.HEADING_5]: (_, children) => <Heading5>{children}</Heading5>,
    [BLOCKS.HEADING_6]: (_, children) => <Heading6>{children}</Heading6>,
    [BLOCKS.UL_LIST]: (_, children) => <ul>{children}</ul>,
    [BLOCKS.OL_LIST]: (_, children) => <ol>{children}</ol>,
    [BLOCKS.LIST_ITEM]: (_, children) => <li>{children}</li>,
    [BLOCKS.QUOTE]: (_, children) => <blockquote>{children}</blockquote>,
    [BLOCKS.HR]: () => <hr />,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const file = node?.data?.target?.fields?.file?.["en-US"]
      const title = node?.data?.target?.fields?.title?.["en-US"]
      const description = node?.data.target?.fields?.description?.["en-US"]

      if (file?.contentType.startsWith("image")) {
        const url = file.url
        const width = file.details.image.width
        const height = file.details.image.height

        return (
          <figure>
            <img alt={title} height={height} src={url} width={width} />
            {description && <figcaption>{description}</figcaption>}
          </figure>
        )
      } else {
        return null
      }
    },
    [BLOCKS.EMBEDDED_ENTRY]: (_, children) => <div>{children}</div>,
    [INLINES.ASSET_HYPERLINK]: (node, children) => (
      <a href={node?.data?.target?.fields?.file?.["en-US"]?.url}>{children}</a>
    ),
    [INLINES.EMBEDDED_ENTRY]: node => {
      const contentful_id = node?.data?.target?.sys?.contentType?.sys?.id
      const contentful_data = node?.data?.target?.fields

      if (!contentful_id) return null

      switch (contentful_id) {
        case "flipText":
          return <FlipText text={contentful_data?.text?.["en-US"]} />

        case "tooltip":
          return (
            <ToolTip
              expandedText={contentful_data?.description?.["en-US"]}
              text={contentful_data?.term?.["en-US"]}
            />
          )

        default:
          return null
      }
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => (
      <a href={node?.data?.uri}>{children}</a>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <a href={node?.data?.uri}>{children}</a>
    ),
  }

  const defaultMarkRenderers = {
    [MARKS.BOLD]: content => {
      if (content[1] === "") return null
      return <b>{content}</b>
    },
    [MARKS.ITALIC]: content => {
      if (content[1] === "") return null
      return <i>{content}</i>
    },
    [MARKS.UNDERLINE]: content => {
      if (content[1] === "") return null
      return <u>{content}</u>
    },
    [MARKS.CODE]: content => {
      if (content[1] === "") return null
      return <code>{content}</code>
    },
  }

  const defaultTextRenderers = content => {
    return content.split("\n").reduce((children, textSegment, index) => {
      return [
        ...children,
        textSegment === "" && <br key={index} />,
        textSegment,
      ]
    }, [])
  }

  const options = {
    renderMark: defaultMarkRenderers,
    renderNode: defaultNodeRenderers,
    renderText: defaultTextRenderers,
  }

  return documentToReactComponents(json, options)
}
