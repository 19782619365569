import React from "react"
export const caretDownDesktop = (
  <svg
    className="caret-desktop"
    fill="none"
    height="17"
    viewBox="0 0 16 17"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3538 6.85372L8.35375 11.8537C8.30732 11.9002 8.25217 11.9371 8.19147 11.9623C8.13077 11.9874 8.06571 12.0004 8 12.0004C7.9343 12.0004 7.86923 11.9874 7.80853 11.9623C7.74783 11.9371 7.69269 11.9002 7.64625 11.8537L2.64625 6.85372C2.55243 6.7599 2.49973 6.63265 2.49973 6.49997C2.49973 6.36729 2.55243 6.24004 2.64625 6.14622C2.74007 6.0524 2.86732 5.99969 3 5.99969C3.13269 5.99969 3.25993 6.0524 3.35375 6.14622L8 10.7931L12.6463 6.14622C12.6927 6.09977 12.7479 6.06292 12.8086 6.03778C12.8693 6.01263 12.9343 5.99969 13 5.99969C13.0657 5.99969 13.1308 6.01263 13.1915 6.03778C13.2521 6.06292 13.3073 6.09977 13.3538 6.14622C13.4002 6.19268 13.4371 6.24783 13.4622 6.30852C13.4873 6.36922 13.5003 6.43428 13.5003 6.49997C13.5003 6.56567 13.4873 6.63072 13.4622 6.69142C13.4371 6.75212 13.4002 6.80727 13.3538 6.85372Z"
      fill="#122F4E"
    />
  </svg>
)
export const caretWhiteDownDesktop = (
  <svg
    className="caret-desktop"
    fill="none"
    height="17"
    viewBox="0 0 16 17"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3538 6.85372L8.35375 11.8537C8.30732 11.9002 8.25217 11.9371 8.19147 11.9623C8.13077 11.9874 8.06571 12.0004 8 12.0004C7.9343 12.0004 7.86923 11.9874 7.80853 11.9623C7.74783 11.9371 7.69269 11.9002 7.64625 11.8537L2.64625 6.85372C2.55243 6.7599 2.49973 6.63265 2.49973 6.49997C2.49973 6.36729 2.55243 6.24004 2.64625 6.14622C2.74007 6.0524 2.86732 5.99969 3 5.99969C3.13269 5.99969 3.25993 6.0524 3.35375 6.14622L8 10.7931L12.6463 6.14622C12.6927 6.09977 12.7479 6.06292 12.8086 6.03778C12.8693 6.01263 12.9343 5.99969 13 5.99969C13.0657 5.99969 13.1308 6.01263 13.1915 6.03778C13.2521 6.06292 13.3073 6.09977 13.3538 6.14622C13.4002 6.19268 13.4371 6.24783 13.4622 6.30852C13.4873 6.36922 13.5003 6.43428 13.5003 6.49997C13.5003 6.56567 13.4873 6.63072 13.4622 6.69142C13.4371 6.75212 13.4002 6.80727 13.3538 6.85372Z"
      fill="#ffffff"
    />
  </svg>
)
export const caretWhiteUpDesktop = (
  <svg
    className="caret-up-desktop"
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.64625 9.64625L7.64625 4.64625C7.69268 4.59976 7.74783 4.56288 7.80853 4.53772C7.86923 4.51255 7.93429 4.4996 8 4.4996C8.0657 4.4996 8.13077 4.51255 8.19147 4.53772C8.25217 4.56288 8.30731 4.59976 8.35375 4.64625L13.3537 9.64625C13.4476 9.74007 13.5003 9.86731 13.5003 10C13.5003 10.1327 13.4476 10.2599 13.3537 10.3537C13.2599 10.4476 13.1327 10.5003 13 10.5003C12.8673 10.5003 12.7401 10.4476 12.6462 10.3537L8 5.70687L3.35375 10.3537C3.30729 10.4002 3.25214 10.4371 3.19144 10.4622C3.13075 10.4873 3.06569 10.5003 3 10.5003C2.9343 10.5003 2.86924 10.4873 2.80855 10.4622C2.74785 10.4371 2.6927 10.4002 2.64625 10.3537C2.59979 10.3073 2.56294 10.2521 2.5378 10.1914C2.51266 10.1307 2.49972 10.0657 2.49972 10C2.49972 9.9343 2.51266 9.86924 2.5378 9.80855C2.56294 9.74785 2.59979 9.6927 2.64625 9.64625Z"
      fill="#ffffff"
    />
  </svg>
)
export const caretDownMobile = (
  <svg
    className="caret-mobile"
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.7075 12.7075L16.7075 22.7075C16.6146 22.8005 16.5043 22.8742 16.3829 22.9246C16.2615 22.9749 16.1314 23.0008 16 23.0008C15.8686 23.0008 15.7385 22.9749 15.6171 22.9246C15.4957 22.8742 15.3854 22.8005 15.2925 22.7075L5.29251 12.7075C5.10487 12.5199 4.99945 12.2654 4.99945 12C4.99945 11.7346 5.10487 11.4801 5.29251 11.2925C5.48015 11.1049 5.73464 10.9995 6.00001 10.9995C6.26537 10.9995 6.51987 11.1049 6.70751 11.2925L16 20.5863L25.2925 11.2925C25.3854 11.1996 25.4957 11.1259 25.6171 11.0756C25.7385 11.0253 25.8686 10.9995 26 10.9995C26.1314 10.9995 26.2615 11.0253 26.3829 11.0756C26.5043 11.1259 26.6146 11.1996 26.7075 11.2925C26.8004 11.3854 26.8741 11.4957 26.9244 11.6171C26.9747 11.7385 27.0006 11.8686 27.0006 12C27.0006 12.1314 26.9747 12.2615 26.9244 12.3829C26.8741 12.5043 26.8004 12.6146 26.7075 12.7075Z"
      fill="white"
    />
  </svg>
)
export const caretUpDesktop = (
  <svg
    className="caret-up-desktop"
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.64625 9.64625L7.64625 4.64625C7.69268 4.59976 7.74783 4.56288 7.80853 4.53772C7.86923 4.51255 7.93429 4.4996 8 4.4996C8.0657 4.4996 8.13077 4.51255 8.19147 4.53772C8.25217 4.56288 8.30731 4.59976 8.35375 4.64625L13.3537 9.64625C13.4476 9.74007 13.5003 9.86731 13.5003 10C13.5003 10.1327 13.4476 10.2599 13.3537 10.3537C13.2599 10.4476 13.1327 10.5003 13 10.5003C12.8673 10.5003 12.7401 10.4476 12.6462 10.3537L8 5.70687L3.35375 10.3537C3.30729 10.4002 3.25214 10.4371 3.19144 10.4622C3.13075 10.4873 3.06569 10.5003 3 10.5003C2.9343 10.5003 2.86924 10.4873 2.80855 10.4622C2.74785 10.4371 2.6927 10.4002 2.64625 10.3537C2.59979 10.3073 2.56294 10.2521 2.5378 10.1914C2.51266 10.1307 2.49972 10.0657 2.49972 10C2.49972 9.9343 2.51266 9.86924 2.5378 9.80855C2.56294 9.74785 2.59979 9.6927 2.64625 9.64625Z"
      fill="black"
    />
  </svg>
)
export const caretUpMobile = (
  <svg
    className="caret-up-mobile"
    fill="none"
    height="32"
    viewBox="0 0 16 16"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.64625 9.64625L7.64625 4.64625C7.69268 4.59976 7.74783 4.56288 7.80853 4.53772C7.86923 4.51255 7.93429 4.4996 8 4.4996C8.0657 4.4996 8.13077 4.51255 8.19147 4.53772C8.25217 4.56288 8.30731 4.59976 8.35375 4.64625L13.3537 9.64625C13.4476 9.74007 13.5003 9.86731 13.5003 10C13.5003 10.1327 13.4476 10.2599 13.3537 10.3537C13.2599 10.4476 13.1327 10.5003 13 10.5003C12.8673 10.5003 12.7401 10.4476 12.6462 10.3537L8 5.70687L3.35375 10.3537C3.30729 10.4002 3.25214 10.4371 3.19144 10.4622C3.13075 10.4873 3.06569 10.5003 3 10.5003C2.9343 10.5003 2.86924 10.4873 2.80855 10.4622C2.74785 10.4371 2.6927 10.4002 2.64625 10.3537C2.59979 10.3073 2.56294 10.2521 2.5378 10.1914C2.51266 10.1307 2.49972 10.0657 2.49972 10C2.49972 9.9343 2.51266 9.86924 2.5378 9.80855C2.56294 9.74785 2.59979 9.6927 2.64625 9.64625Z"
      fill="white"
    />
  </svg>
)
