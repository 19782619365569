import React from "react"
import PropTypes from "prop-types"
import Menu from "./Menu"
import Actions from "./Actions"
import Copyright from "./Copyright"
import "./footer.scss"
import { renderClasses } from "utils/renderClasses"
const Footer = ({
  backgroundColor,
  className,
  menu,
  scheduleAppointment,
  socialLinks,
  utilityLinks,
}) => (
  <footer
    className={renderClasses(`g-footer ${className}`, [
      [backgroundColor, `--${backgroundColor}`],
    ])}
  >
    <div className="max-container">
      <Menu {...menu} />
      <Actions
        scheduleAppointment={scheduleAppointment}
        socialLinks={socialLinks}
      />
      <Copyright utilityLinks={utilityLinks} />
    </div>
  </footer>
)

Footer.props = {
  ...Actions.props,
  ...Copyright.props,
  menu: PropTypes.shape(Menu.props),
}

Footer.propTypes = Footer.props

export default Footer
