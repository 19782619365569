import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import { renderRichText } from "utils/renderRichText"
import { renderClasses } from "utils/renderClasses"
import { ParagraphTypes } from "../props"
import "./wysiwyg.scss"

const Wysiwyg = forwardRef(({ className, isAccordion, text }, ref) => (
  <div
    className={renderClasses("g-wysiwyg", [[className, className]])}
    ref={ref}
  >
    {renderRichText({ ...text, isAccordion })}
  </div>
))

Wysiwyg.props = {
  className: PropTypes.string,
  text: PropTypes.shape({
    json: PropTypes.object,
    type: PropTypes.oneOf(ParagraphTypes),
  }),
  isAccordion: PropTypes.bool,
}

Wysiwyg.displayName = "Wysiwyg"

Wysiwyg.propTypes = Wysiwyg.props

export default Wysiwyg
