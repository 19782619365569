import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { renderClasses } from "utils/renderClasses"
import "./links.scss"
import {
  caretDownDesktop,
  caretDownMobile,
  caretUpDesktop,
  caretUpMobile,
  caretWhiteUpDesktop,
  caretWhiteDownDesktop,
} from "./icons"

const Links = ({ isWholeChild, menuOpen, menus }) => {
  const [dropdownOpen, setDropdownOpen] = useState({})
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1026)
    }
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const handleMouseEnter = useCallback(
    (menuIndex, linkIndex) => {
      if (!isMobile) {
        const key = `dropdown-${menuIndex}-${linkIndex}`
        setDropdownOpen(prev => ({ ...prev, [key]: true }))
      }
    },
    [isMobile]
  )

  const handleMouseLeave = useCallback(
    (menuIndex, linkIndex) => {
      if (!isMobile) {
        const key = `dropdown-${menuIndex}-${linkIndex}`
        setDropdownOpen(prev => ({ ...prev, [key]: false }))
      }
    },
    [isMobile]
  )

  const handleClick = useCallback(
    (menuIndex, linkIndex) => {
      if (isMobile) {
        const key = `dropdown-${menuIndex}-${linkIndex}`
        setDropdownOpen(prev => ({ ...prev, [key]: !prev[key] }))
      }
    },
    [isMobile]
  )
  const getCaretIcon = key => {
    if (isWholeChild) {
      return dropdownOpen[key] ? caretWhiteUpDesktop : caretWhiteDownDesktop
    }
    return dropdownOpen[key] ? caretUpDesktop : caretDownDesktop
  }
  return (
    <nav
      className={renderClasses("g-header-top-nav-menu-links", [
        [menuOpen, "--open"],
      ])}
    >
      {menus.map((menu, menuIndex) => (
        <ul key={menu.label || menuIndex}>
          {menu.links.map((link, linkIndex) => {
            const key = `dropdown-${menuIndex}-${linkIndex}`
            const isGroupTherapyPrograms =
              link.path === "/whole-child/GroupTherapyPrograms"
            return (
              <li
                key={link.path}
                onClick={() => handleClick(menuIndex, linkIndex)}
                onMouseEnter={() => handleMouseEnter(menuIndex, linkIndex)}
                onMouseLeave={() => handleMouseLeave(menuIndex, linkIndex)}
              >
                <div className="link-with-caret">
                  <Link
                    activeClassName="g-header-top-nav-menu-links__link--active"
                    style={{
                      color: menuOpen || isWholeChild ? "#ffffff" : "",
                    }}
                    to={link.path}
                  >
                    {link.text}
                  </Link>
                  {link.nestedLinks?.length > 0 && (
                    <span className="dropdown-caret">
                      {getCaretIcon(key)}
                      {dropdownOpen[key] ? caretUpMobile : caretDownMobile}
                    </span>
                  )}
                </div>
                {link.nestedLinks?.length > 0 && dropdownOpen[key] && (
                  <ul
                    className={`dropdown-content ${
                      dropdownOpen[key] ? "dropdown-open" : ""
                    }`}
                  >
                    {link.nestedLinks.map(nestedLink => (
                      <li key={nestedLink.path}>
                        <Link
                          to={
                            isGroupTherapyPrograms
                              ? `${link.path}#${nestedLink.text
                                  .replace(/\s+/g, "-")
                                  .toLowerCase()}`
                              : nestedLink.path
                          }
                        >
                          {nestedLink.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )
          })}
        </ul>
      ))}
    </nav>
  )
}

Links.props = {
  isWholeChild: PropTypes.bool,
  menus: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          path: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
          nestedLinks: PropTypes.arrayOf(
            PropTypes.shape({
              path: PropTypes.string.isRequired,
              text: PropTypes.string.isRequired,
            })
          ),
        })
      ),
    })
  ),
}

Links.propTypes = {
  ...Links.props,
  menuOpen: PropTypes.bool.isRequired,
}
Links.defaultProps = {
  isWholeChild: false,
}

export default Links
